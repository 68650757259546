@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "PTSans";
    src: local("PTSans"),
    url('./resources/fonts/PTSans-Regular.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "PTSans";
    src: local("PTSans"),
    url('./resources/fonts/PTSans-Bold.ttf') format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: "PTSans";
    src: local("PTSans"),
    url('./resources/fonts/PTSans-BoldItalic.ttf') format("truetype");
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: "PTSans";
    src: local("PTSans"),
    url('./resources/fonts/PTSans-Italic.ttf') format("truetype");
    font-weight: normal;
    font-style: italic;
  }

  html {
    @apply text-primary;
  }

  a {
    @apply text-secondary;
  }

  a:hover {
    @apply text-secondary-dark;
    @apply underline;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply mb-2;
  }
  h1 {
    @apply text-5xl;
  }
  h2, article h1 {
    @apply text-4xl;
  }
  h3, article h2 {
    @apply text-3xl;
  }
  h4, article h3 {
    @apply text-2xl;
  }
  h5, article h4 {
    @apply text-xl;
  }

  article ul, .ck-content ul, .content ul {
    @apply list-disc pl-5;
  }

  article ol, .ck-content ol, .content ol {
    @apply list-decimal pl-5;
  }

  article ul ul, article ul ol, article ol ul, article ol ol, .ck-content ul ul, .ck-content ul ol, .ck-content ol ul, .ck-contentol ol, .content ul ul, .content ul ol, .content ol ul, .content ol ol{
    @apply pl-4;
  }

  dl {
    @apply grid sm:grid-cols-3 gap-x-2;
  }
  dt {
    @apply col-start-1 font-bold border-b;
  }
  dd {
    @apply col-span-2 col-start-2 pb-4 border-b;
  }

  .toggle-checkbox:checked {
    @apply: right-0 border-green-400 bg-green-400;
    right: 0;
    border-color: #34D399;
  }
  .toggle-checkbox:checked + .toggle-label {
    @apply: bg-green-400;
    background-color: #34D399;
  }

  .fc-bg-event {
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
  }

  .fc-view-harness {
    @apply min-h-screen;
  }

  .react-datepicker {
    @apply absolute bg-white shadow-lg rounded-lg border border-secondary p-2 z-50 flex flex-row;
  }
  .react-datepicker__input-container input {
    @apply block mt-2 p-3
    border
    focus:border-secondary focus:outline-none
    focus:ring-1 focus:ring-secondary
    w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
  }
  .react-datepicker__tab-loop {
    @apply absolute;
  }
  .react-datepicker-popper {
    @apply absolute top-1 z-50 left-0 w-72 text-sm transform-none px-3 py-2 !important;
  }
  .react-datepicker__month-container {
    @apply flex flex-col;
  }
  .react-datepicker__month {
    @apply flex flex-col;
  }
  .react-datepicker__current-month {
    @apply ml-2.5 text-lg font-semibold;
  }
  .react-datepicker__week {
    @apply flex justify-around;
  }
  .react-datepicker__day-names {
    @apply flex justify-around font-medium text-center text-xs;
  }
  .react-datepicker__day-name {
    @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
  }
  .react-datepicker__navigation {
    @apply absolute top-2;
  }
  .react-datepicker__navigation--previous {
    @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
  }
  .react-datepicker__navigation--next {
    @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
  }
  .react-datepicker__day {
    @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition rounded hover:bg-primary hover:text-white;
  }
  .react-datepicker__day--disabled, .react-datepicker__time-list-item--disabled {
    @apply cursor-not-allowed opacity-50 !important;
  }
  .react-datepicker__day--outside-month {
    @apply text-gray-400;
  }
  .react-datepicker__day--in-range {
    @apply bg-gray-200;
  }
  .react-datepicker__day--in-selecting-range {
    @apply bg-blue-200;
  }
  .react-datepicker__day--selecting-range-start {
    @apply bg-white border-2 border-blue-500;
  }
  .react-datepicker__day--selecting-range-end {
    @apply bg-white border-2 border-blue-500;
  }
  .react-datepicker__day--selected, .react-datepicker__time-list-item--selected {
    @apply bg-primary text-white hover:bg-opacity-50 !important;
  }
  .react-datepicker__day--range-start {
    @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
  }
  .react-datepicker__day--range-end {
    @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
  }
  .react-datepicker__time-container {
    @apply w-24 mt-8 border-l border-secondary ml-2 pl-3;
  }
  .react-datepicker__header--time {
    @apply font-medium;
  }
  .react-datepicker__time {
    @apply mt-2 pt-2 border-t border-secondary py-2 h-56;
  }
  .react-datepicker__time-box {
    @apply pl-2 overflow-auto h-full;
  }
  .react-datepicker__time-list {
    @apply space-y-2;
  }
  .react-datepicker__time-list-item {
    @apply p-2 rounded-lg cursor-pointer hover:bg-primary hover:text-white text-center;
  }

  .select-list {
    @apply divide-secondary divide-y-2 flex flex-col  rounded-md border-opacity-70 overflow-hidden;
  }

  .select-list li {
    @apply py-2 text-center px-2 hover:cursor-pointer hover:bg-secondary hover:text-white;
  }

  .select-list .selected {
    @apply bg-primary text-white;
  }
}
@responsive {
  .min-h-0	{
    min-height: 0px;
  }
  .min-h-px	{
    min-height: 1px;
  }
  .min-h-0\.5	{
    min-height: 0.125rem;
  }
  .min-h-1	{
    min-height: 0.25rem;
  }
  .min-h-1\.5	{
    min-height: 0.375rem;
  }
  .min-h-2	{
    min-height: 0.5rem;
  }
  .min-h-2\.5	{
    min-height: 0.625rem;
  }
  .min-h-3	{
    min-height: 0.75rem;
  }
  .min-h-3\.5	{
    min-height: 0.875rem;
  }
  .min-h-4	{
    min-height: 1rem;
  }
  .min-h-5	{
    min-height: 1.25rem;
  }
  .min-h-6	{
    min-height: 1.5rem;
  }
  .min-h-7	{
    min-height: 1.75rem;
  }
  .min-h-8	{
    min-height: 2rem;
  }
  .min-h-9	{
    min-height: 2.25rem;
  }
  .min-h-10	{
    min-height: 2.5rem;
  }
  .min-h-11	{
    min-height: 2.75rem;
  }
  .min-h-12	{
    min-height: 3rem;
  }
  .min-h-14	{
    min-height: 3.5rem;
  }
  .min-h-16	{
    min-height: 4rem;
  }
  .min-h-20	{
    min-height: 5rem;
  }
  .min-h-24	{
    min-height: 6rem;
  }
  .min-h-28	{
    min-height: 7rem;
  }
  .min-h-32	{
    min-height: 8rem;
  }
  .min-h-36	{
    min-height: 9rem;
  }
  .min-h-40	{
    min-height: 10rem;
  }
  .min-h-44	{
    min-height: 11rem;
  }
  .min-h-48	{
    min-height: 12rem;
  }
  .min-h-52	{
    min-height: 13rem;
  }
  .min-h-56	{
    min-height: 14rem;
  }
  .min-h-60	{
    min-height: 15rem;
  }
  .min-h-64	{
    min-height: 16rem;
  }
  .min-h-72	{
    min-height: 18rem;
  }
  .min-h-80	{
    min-height: 20rem;
  }
  .min-h-96	{
    min-height: 24rem;
  }
  .min-h-1\/2	{
    min-height: 50%;
  }
  .min-h-1\/3	{
    min-height: 33.333333%;
  }
  .min-h-2\/3	{
    min-height: 66.666667%;
  }
  .min-h-1\/4	{
    min-height: 25%;
  }
  .min-h-2\/4	{
    min-height: 50%;
  }
  .min-h-3\/4	{
    min-height: 75%;
  }
  .min-h-1\/5	{
    min-height: 20%;
  }
  .min-h-2\/5	{
    min-height: 40%;
  }
  .min-h-3\/5	{
    min-height: 60%;
  }
  .min-h-4\/5	{
    min-height: 80%;
  }
  .min-h-1\/6	{
    min-height: 16.666667%;
  }
  .min-h-2\/6	{
    min-height: 33.333333%;
  }
  .min-h-3\/6	{
    min-height: 50%;
  }
  .min-h-4\/6	{
    min-height: 66.666667%;
  }
  .min-h-5\/6	{
    min-height: 83.333333%;
  }
}